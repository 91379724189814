import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PROJETOS_PORT}${process.env.VUE_APP_API_PATH}`;
const urlBase = `${api}/agendamentos`;

export default {
    obterAgendamentos(filtro) {
        return axiosJwt.get(`${urlBase}?dataInicial=${filtro.dataInicial ? filtro.dataInicial : ''}&dataFinal=${filtro.dataFinal ? filtro.dataFinal : ''}`);
    },
};
