<template>
    <font-awesome-icon icon="info-circle" @click="detalhar()" size="lg" class="icon-button p-text-primary" title="Detalhar" v-if="$temAcessoView('AGENDAMENTOS-00')" />
</template>

<script>
export default {
    props: {
        agendamento: {
            type: Object,
        },
    },

    methods: {
        detalhar() {
            let routeData = this.$router.resolve({
                name: 'Credores_Detalhar',
                params: {
                    projetoId: this.agendamento.projetoId,
                    id: this.agendamento.credorId,
                },
            });
            window.open(routeData.href, '_blank');
        },
    },
};
</script>
