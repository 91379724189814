<template>
    <div class="p-grid" v-if="mostrarListagem">
        <div class="p-col-12">
            <div class="card">
                <div class="p-d-flex">
                    <h5><i class="pi pi-calendar-times"></i> Agendamentos</h5>
                    <btn-refresh @click="atualizar()"></btn-refresh>
                </div>
                <Panel header="Filtros" :toggleable="true" class="filtro">
                    <div class="p-formgrid p-grid p-fluid p-mt-2">
                        <div class="p-field p-col-12 p-md-3 p-lg-2">
                            <label>Próximo Contato (DE)</label>
                            <Calendar v-model="proximoContatoDe" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" :manualInput="false" />
                        </div>
                        <div class="p-field p-col-12 p-md-3 p-lg-2">
                            <label>Próximo Contato (ATÉ)</label>
                            <Calendar v-model="proximoContatoAte" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" :manualInput="false" />
                        </div>
                    </div>
                </Panel>
                <tabela :data="agendamentos" dataKey="credorId" :globalFilterFields="['projeto', 'nome']" :filtersProp="filters" :filterCallBack="dadosFiltrados" stateKey="dt-agendamentos" :small="true">
                    <template #conteudo>
                        <Column headerStyle="width: 3em">
                            <template #body="slotProps">
                                <btn-detalhar :agendamento="slotProps.data"></btn-detalhar>
                            </template>
                        </Column>
                        <Column header="Próx. Contato" :sortable="true" field="proximoContato" dataType="date">
                            <template #body="{ data }">
                                {{ data.proximoContato ? $moment(data.proximoContato).format('DD/MM/YYYY HH:mm') : '' }}
                            </template>
                        </Column>
                        <Column field="projeto" header="Projeto" :sortable="true">
                            <template #body="{ data }">
                                {{ data.projeto }}
                            </template>
                        </Column>
                        <Column field="nome" header="Credor" :sortable="true">
                            <template #body="{ data }">
                                {{ data.nome }}
                            </template>
                        </Column>
                    </template>
                </tabela>
            </div>
        </div>
    </div>
</template>

<script>
import AgendamentosServices from './services';
import BtnDetalhar from './BtnDetalhar';

export default {
    components: {
        BtnDetalhar,
    },

    data() {
        return {
            agendamentos: null,
            proximoContatoAte: null,
            proximoContatoDe: null,
        };
    },

    created() {},

    methods: {
        obterAgendamentos() {
            this.$store.dispatch('addRequest');
            let filtroDto = {
                dataInicial: this.$moment(this.proximoContatoDe).format('YYYY-MM-DD'),
                dataFinal: this.$moment(this.proximoContatoAte).format('YYYY-MM-DD'),
            };
            AgendamentosServices.obterAgendamentos(filtroDto).then((response) => {
                if (response && response.success) {
                    this.agendamentos = response.data;
                } else {
                    this.agendamentos = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.obterAgendamentos();
        },
    },

    mounted() {
        let dataAtual = new Date();
        console.log(dataAtual);
        this.proximoContatoAte = new Date(this.$moment(dataAtual).add(1, 'days').format('YYYY-MM-DD'));
        this.proximoContatoDe = new Date(this.$moment(dataAtual).subtract(1, 'days').format('YYYY-MM-DD'));
        this.obterAgendamentos();
    },

    computed: {
        mostrarListagem() {
            return this.$route.name === 'Agendamentos';
        },

        yearRange() {
            let anoFinal = new Date().getFullYear() + 1;
            let anoInicial = anoFinal - 2;
            return `${anoInicial}:${anoFinal}`;
        },
    },

    watch: {
        $route(to) {
            if (to.name === 'Agendamentos') {
                if (this.$store.getters.atualizar) {
                    this.obterAgendamentos();
                    this.$store.dispatch('setAtualizar', false);
                }
            }
        },

        proximoContatoDe() {
            this.obterAgendamentos();
        },

        proximoContatoAte() {
            this.obterAgendamentos();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto;
    }
}

::v-deep(.p-datepicker) {
    min-width: 25rem;

    td {
        font-weight: 400;
    }
}
</style>
